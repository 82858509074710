import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../LanguageContext";
import fetchContentBySlug from "../api";
import ComponentHeroHomepage from "../components/hero-home.js";
import ComponentLogoGrid from "../components/logo-grid.js";
import { Helmet } from "react-helmet-async";
import config from "../config";

const HomePage = () => {
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const debounceTimer = useRef(null); // Ref to hold the debounce timer
  const navigate = useNavigate();
  const { availableLocales, currentLocale, updateLocales, changeLocale } =
    useLanguage();

  useEffect(() => {
    const fetchContent = () => {
      const pathname = window.location.pathname;
      const slug =
        pathname.endsWith("/") && pathname !== "/"
          ? pathname.slice(0, -1)
          : pathname;
      const routeSlug = slug.startsWith("/") ? slug.slice(1) : slug;
      const localeInsideSlug = routeSlug.split("/")[0];
      const locale = localeInsideSlug.length === 2 ? localeInsideSlug : "de";
      const type = "pages";

      fetchContentBySlug(type, routeSlug)
        .then((data) => {
          if (!data.data.length) {
            throw new Error("Page not found");
          }

          setContent(data.data[0].attributes);

          // find all translations
          const availableLocales =
            data.data[0].attributes.localizations.data.map((item) => ({
              locale: item.attributes.locale,
              slug: item.attributes.slug,
            }));
          updateLocales(availableLocales);
          changeLocale(locale);
        })
        .catch(() => {
          navigate("/404");
        })
        .finally(() => {
          setLoading(false);
        });
    };
    // Debounce the API call
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
    debounceTimer.current = setTimeout(() => {
      fetchContent();
    }, 300); // 300ms debounce delay

    // Cleanup on unmount
    return () => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
    };
  }, [navigate, updateLocales, changeLocale]);

  return (
    <>
      {loading ? (
        <div>ladenvorgang...</div>
      ) : (
        <>
          <Helmet>
            <html lang={currentLocale} />
            <title>{`Metrilus - ${content.internalTitle}`}</title>
            <meta name="description" content={content.meta_description} />
            <meta
              property="og:title"
              content={`Metrilus - ${content.internalTitle}`}
            />
            <meta
              property="og:description"
              content={content.meta_description}
            />
            <meta
              property="og:image"
              content={`${config.apiBaseUrl}${content.heroImage.data.attributes.url}`}
            />
            <meta
              property="og:url"
              content={`${config.baseUrl}${content.slug}`}
            />
            {availableLocales.length > 0 && (
              <>
                {availableLocales.map((locale) => (
                  <link
                    rel="alternate"
                    key={locale.locale}
                    href={`${window.location.origin}/${locale.slug}`}
                    hreflang={locale.locale}
                  />
                ))}
              </>
            )}
          </Helmet>
          <div className="homepage-components">
            {content.content.map((component, index) => {
              if (!component?.__component) return null;
              const uniqueKey = `component_${index}`;
              switch (component.__component) {
                case "content.home-hero":
                  return (
                    <ComponentHeroHomepage
                      data={component}
                      key={uniqueKey}
                      uniqueKey={uniqueKey}
                    />
                  );
                case "content.logo-grid":
                  return (
                    <ComponentLogoGrid
                      data={component}
                      key={uniqueKey}
                      uniqueKey={uniqueKey}
                      customClass="component-logo-grid--homepage"
                    />
                  );
                default:
                  return null;
              }
            })}
          </div>
        </>
      )}
    </>
  );
};

export default HomePage;
