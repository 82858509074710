import React, { useState, useEffect } from "react";
import config from "../config";
import { useLanguage } from "../LanguageContext";

const ComponentJobListing = ({ uniqueKey, data }) => {
  const {
    job_Offer,
    job_ApplyText,
    teaserHeading,
    teaserText,
    job_RoleHeading,
    job_RoleText,
    job_Roles,
    job_Tables,
    job_Whys,
    applicationForm,
    image1,
    image2,
    image3,
  } = data.job.data.attributes;
  const darkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const { currentLocale } = useLanguage();
  const [loading, setLoading] = useState(true);
  const [stringTable, setStringTable] = useState(null);

  const fetchStringTable = async (locale) => {
    try {
      const response = await fetch(
        `${config.apiBaseUrl}/api/string-table?populate=*&locale=${locale}`
      );
      if (!response.ok) {
        throw new Error("Navigation not found");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching navigation:", error);
      throw error;
    }
  };

  useEffect(() => {
    // hubspot form script tag
    const script = document.createElement("script");
    script.src = "//js-eu1.hsforms.net/forms/embed/v2.js";
    script.type = "text/javascript";
    script.async = true;
    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: applicationForm?.portalId,
          formId: darkMode
            ? applicationForm?.formIdDark
            : applicationForm?.formId,
          target: `#form_${uniqueKey}`,
          onFormReady: function ($form) {
            const inputField = $form.querySelector(
              'input[name="TICKET.subject"]'
            );
            inputField.value = data.job.data.id;
          },
        });
      }
    };
    document.body.appendChild(script);

    setLoading(true);
    fetchStringTable(currentLocale)
      .then((data) => {
        // setStringTable(data.data.attributes.string);

        const stringArray = data.data.attributes.string;

        // Transform the array into an object without modifying the keys
        const stringObj = stringArray.reduce((acc, cur) => {
          acc[cur.key] = cur.value;
          return acc;
        }, {});

        setStringTable(stringObj);

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });

    // remove script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, [uniqueKey, data, applicationForm, darkMode, currentLocale]);

  return loading ? (
    <div>Loading...</div>
  ) : (
    <>
      <div
        key={`component_${uniqueKey}`}
        className="component component-job-listing"
      >
        {stringTable.job_Offer && (
          <div className="meta-headline">{stringTable.job_Offer}</div>
        )}
        <h2>{job_Offer}</h2>
        <h3>{teaserHeading}</h3>
        <p>{teaserText}</p>

        {image1 && (
          <figure className="component-figure">
            <img
              src={`${config.apiBaseUrl}${image1.data.attributes.url}`}
              alt={image1.data.attributes.alt}
            />
            {image1.data.attributes.alt && (
              <figcaption>{image1.data.attributes.alt}</figcaption>
            )}
          </figure>
        )}

        {job_RoleHeading && (
          <h3>
            {stringTable.job_Role && stringTable.job_Role}: {job_RoleHeading}
          </h3>
        )}
        {job_RoleText && <p>{job_RoleText}</p>}

        <ul>
          {job_Roles?.map((role) => (
            <li key={role.id}>
              <strong>{role.strongText}</strong>: {role.normalText}
            </li>
          ))}
        </ul>

        {image2 && (
          <figure className="component-figure">
            <img
              src={`${config.apiBaseUrl}${image2.data.attributes.url}`}
              alt={image2.data.attributes.alt}
            />
            {image2.data.attributes.alt && (
              <figcaption>{image2.data.attributes.alt}</figcaption>
            )}
          </figure>
        )}

        {stringTable.job_Table && <h3>{stringTable.job_Table}</h3>}
        <ul>
          {job_Tables?.map((table) => (
            <li key={table.id}>{table.normalText}</li>
          ))}
        </ul>

        {image3 && (
          <figure className="component-figure">
            <img
              src={`${config.apiBaseUrl}${image3.data.attributes.url}`}
              alt={image3.data.attributes.alt}
            />
            {image3.data.attributes.alt && (
              <figcaption>{image3.data.attributes.alt}</figcaption>
            )}
          </figure>
        )}

        {stringTable.job_Why && <h3>{stringTable.job_Why}</h3>}
        <ul>
          {job_Whys?.map((why) => (
            <li key={why.id}>
              <strong>{why.strongText}</strong>: {why.normalText}
            </li>
          ))}
        </ul>

        {stringTable.job_Apply && <h3>{stringTable.job_Apply}</h3>}
        <p>{job_ApplyText}</p>
      </div>
      <div id={`form_${uniqueKey}`} className="hubspot__form"></div>
    </>
  );
};

export default ComponentJobListing;
