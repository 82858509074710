import React, { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import ReactGA from "react-ga4";
import { LanguageProvider, useLanguage } from "./LanguageContext";
import NavHeader from "./components/nav-header";
import NavFooter from "./components/nav-footer";
import ComponentsPage from "./pages/Components";
import HomePage from "./pages/Home";
import JobsPage from "./pages/Jobs";
import config from "./config";

const AppContent = () => {
  const { navigationOptions, loading, currentLocale } = useLanguage();
  const location = useLocation(); // Get current route

  // google analytics
  useEffect(() => {
    if (config.googleAnalyticsId) {
      ReactGA.initialize(config.googleAnalyticsId);
    }
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return loading ? (
    <div>Loading...</div>
  ) : (
    <>
      {navigationOptions && <NavHeader options={navigationOptions} />}
      <article className="main" role="main">
        <Routes>
          {/* Default redirect to locale-prefixed homepage */}
          <Route
            path="/"
            element={<Navigate to={`/${currentLocale}`} replace />}
          />

          {/* Locale-prefixed routes */}
          <Route path="/:locale" element={<HomePage />} />
          <Route path="/:locale/jobs/*" element={<JobsPage />} />
          <Route path="/:locale/*" element={<ComponentsPage />} />
          {/* Catch-all for 404 */}
          <Route path="*" element={<ComponentsPage />} />
        </Routes>
      </article>
      {navigationOptions && <NavFooter options={navigationOptions} />}
    </>
  );
};

const App = () => (
  <HelmetProvider>
    <LanguageProvider>
      <Router>
        <AppContent />
      </Router>
    </LanguageProvider>
  </HelmetProvider>
);

export default App;
